import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PrismicProvider } from '@prismicio/react';
import client from './prismicio';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Politic from './components/Politic';
import Thanks from './components/Thanks';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PrismicProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<App />}
        />
          <Route
            path="/politic"
            element={<Politic />}
          />
          <Route
            path="/thanks"
            element={<Thanks />}
          />
      </Routes>
    </BrowserRouter>
    {/* <App /> */}
  </PrismicProvider>
);




