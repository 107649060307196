import React, { useState } from 'react';
import logo from '../img/logo.svg';
import styles from '../Home.module.css';
import ModalPolitic from './Form';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className={`${styles.footer}`}>
      <div className={`${styles.footerContainer}`}>
        <div className={`${styles.footerBox}`}>
          <img
            alt="logo"
            src={logo}
          />
          <p className={`${styles.reserve}`}>© 2023. Все права защищены</p>
          <p className={`${styles.politicP}`}>
            <Link
              className={`${styles.politic}`}
              to="/politic"
            >
              Политика конфиденциальности
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
