import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

import styles from '../Home.module.css';

import blur from '../img/thx1.png';
import blur2 from '../img/thx2.png';
// import blur3 from '../img/hero-mob.png';
import dateIcon from '../img/date.svg';
import timeIcon from '../img/time.svg';

const Thx = ({ slice }) => {
  const time = prismicH.asText(slice.primary.time);
  const date = prismicH.asText(slice.primary.date);
  const btn = prismicH.asText(slice.primary.btn);
  const desc = prismicH.asText(slice.primary.btnunder);

  return (
    <section className={`${styles.thanks}`}>
      <img
        src={blur}
        alt="Hero blur"
        className={`${styles.blurImgThx1}`}
      />
      <img
        src={blur2}
        alt="Hero blur"
        className={`${styles.blurImgThx2}`}
      />
      <div className={`${styles.thanksBox}`}>
        <div className={`${styles.thanksSub}`}>
          <PrismicRichText field={slice.primary.subtitle} />
        </div>
        <div className={`${styles.dateBox}`}>
          <p className={`${styles.date}`}>
            <img
              src={dateIcon}
              alt="Icon"
            />
            {date}
          </p>
          <p className={`${styles.time}`}>
            <img
              src={timeIcon}
              alt="Icon"
            />
            {time}
          </p>
        </div>
        <div className={`${styles.titleThx}`}>
          <PrismicRichText field={slice.primary.title} />
          <PrismicRichText field={slice.primary.desc} />
        </div>
        <div>
          <a
            className={`${styles.btn} ${styles.btnThx}`}
            href="https://t.me/tatiana_havro_bot?start=64e89793d4df0a44c305a899"
            target="_blank"
            rel="noreferrer"
          >
            {btn}
          </a>
          <p className={`${styles.desc} ${styles.descThx}`}>{desc}</p>
        </div>
      </div>
    </section>
  );
};

export default Thx;
