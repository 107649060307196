import * as prismicH from '@prismicio/helpers';
import styles from '../Home.module.css';
import { PrismicRichText } from '@prismicio/react';
import blur from '../img/blurSec5.png';
import Form from './Form';
import { nanoid } from 'nanoid';

const Section5 = ({ slice }) => {
  return (
    <>
      <section
        className={`${styles.section5}`}
        id="form"
      >
        <div className={`${styles.boxContainer5}`}>
          <div className={`${styles.boxSec5}`}>
            <div className={`${styles.container5}`}>
              <img
                src={blur}
                alt="blur"
                className={`${styles.blurImg3}`}
              />
              <div className={`${styles.content}`}>
                <div className={`${styles.subtitle5}`}>
                  <PrismicRichText field={slice.primary.suptitle} />
                </div>
                <div className={`${styles.title5}`}>
                  <PrismicRichText field={slice.primary.title} />
                </div>
                <div>
                  {slice.items.map((item) => {
                    const src = prismicH.asImageSrc(item.icon);
                    return (
                      <div
                        className={`${styles.itemSec5}`}
                        key={nanoid}
                      >
                        <img
                          src={src}
                          alt="icon list"
                        />
                        <PrismicRichText
                          key={item.item}
                          field={item.item}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <Form />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section5;
