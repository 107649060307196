import { SliceZone, usePrismicDocumentByUID } from '@prismicio/react';
import Footer from './Footer';
import Thx from './Thx';
import { Helmet } from 'react-helmet';

const Thanks = () => {
  const [page, { state }] = usePrismicDocumentByUID('homepage', 'main');
  return (
    <>
      <Helmet>
        <script>
          {`
            !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod
                  ? n.callMethod.apply(n, arguments)
                  : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(
              window,
              document,
              'script',
              'https://connect.facebook.net/en_US/fbevents.js'
            );
            fbq('init', '1195200408090047');
            fbq('track', 'PageView');
            fbq('track', 'Lead');
          `}
        </script>
      </Helmet>
      {state === 'loaded' ? (
        <div>
          <SliceZone
            slices={page.data.body}
            components={{
              thanks: Thx,
            }}
          />
          <Footer />
          <noscript>
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=1195200408090047&ev=PageView&noscript=1"
            />
          </noscript>
        </div>
      ) : (
        'Loading...'
      )}
    </>
  );
};

export default Thanks;
