import { SliceZone, usePrismicDocumentByUID } from '@prismicio/react';

import { Helmet } from 'react-helmet';

import Sec1 from './components/Hero';
import Sec2 from './components/Section2';
import Sec3 from './components/Section3';
import Sec4 from './components/Section4';
import Sec5 from './components/Section5';
import Footer from './components/Footer';

function App() {

  const [page, {state}] = usePrismicDocumentByUID('homepage', 'main');

  return (
    <>
      {/* <Helmet>
        <script
          src="//web.webformscr.com/apps/fc3/build/loader.js"
          defer
          sp-form-id="0d290e6a394396fa0ed9b4df96508577c7ab7a328e27851eb4dd9a891967d04b"
        ></script>
        <script
          type="text/javascript"
          defer
          src="//web.webformscr.com/apps/fc3/build/default-handler.js?1689226962100"
        ></script>
      </Helmet> */}
      {state === 'loaded' ? (
        <div>
          <SliceZone
            slices={page.data.body}
            components={{
              hero: Sec1,
              section2: Sec2,
              section3: Sec3,
              section4: Sec4,
              section5: Sec5,
            }}
          />
          <Footer />
        </div>
      ) : (
        'Loading...'
      )}
    </>
  );
}

export default App;



