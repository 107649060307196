import styles from '../Home.module.css';
import { Link } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';
import { useEffect } from 'react';

const Politic = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={`${styles.politicSection}`}>
      <Link
        to="/"
        className={`${styles.politicClose}`}
      >
        <GrClose />
      </Link>
      <Link
        to="/"
        className={`${styles.politicBackLink}`}
      >
        Назад
      </Link>
      <h3 className={`${styles.politicTitle}`}>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h3>
      <p className={`${styles.politicDesc}`}>
        Вся інформація надана Користувачами сайту, обробляється відповідно до
        цієї Політики конфіденційності персональних даних (далі за текстом –
        Політика).
      </p>
      <h4 className={`${styles.politicSubTitle}`}>1. ТЕРМІНИ</h4>
      <p className={`${styles.politicDesc}`}>
        1.1. У цій Політиці використовуються такі терміни:
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.1. «Адміністратор сайту (далі по тексту – «Адміністратор») –
        уповноважена особа / особи, які беруть участь в управлінні сайтом, які
        належним чином уповноважені діяти від імені ТОВАРИСТВА З ОБМЕЖЕНОЮ
        ВІДПОВІДАЛЬНІСТЮ «ЕР ДЖІ БІ», які здійснюють обробку персональних даних,
        а також визначають мету обробки персональних даних, їх склад, і
        операції, що здійснюються з персональними даними..
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.2. «Персональні дані» – відомості чи сукупність відомостей про
        фізичну особу, яка ідентифікована або може бути конкретно ідентифікована
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.3. «Обробка персональних даних» – будь-які дії, що здійснюються з
        використанням засобів автоматизації або без використання таких засобів з
        персональними даними (в тому числі збір, запис, систематизацію,
        накопичення, зберігання, уточнення (оновлення, зміна), витяг,
        використання, передачу (поширення, надання, доступ), знеособлення,
        блокування, видалення, знищення персональних даних).
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.4. «Конфіденційність персональних даних» – обов’язкове для
        дотримання Оператором або іншою особою, що отримала доступ до
        персональних даних, вимог про недопущення їх поширення без згоди
        Користувача або наявності іншої законної підстави.
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.5. «Користувач сайту (далі – Користувач)» – особа, яка має доступ до
        сайту, за допомогою мережі Інтернет і використовує сайт.
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.6. «Cookies» – невеликий фрагмент даних, відправлений веб-сервером,
        що зберігається на комп’ютері користувача, який веб-браузер кожен раз
        пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку
        відповідного сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.7. «IP-адреса» – унікальна мережева адреса вузла в комп’ютерній
        мережі, побудованої за протоколом IP.
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.8. Сайт (далі – «сайт») – сайт «https://rgbweb.studio», розташований
        на доменному імені rgbweb.studio.
      </p>
      <p className={`${styles.politicDesc}`}>
        1.1.9. Оператор (далі – «Оператор») – особа, офіційний представник
        сайту, Фізичної особи-підприємця Хавро Тетяни Анатоліївни, яка вчиняє на
        підставі довіреності або на підставі іншого документа, що дає право
        діяти в інтересах і від імені ТОВАРИСТВА З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ
        «ЕР ДЖІ БІ» дії, пов’язані з консультуванням Користувача з питань,
        пов’язаних з розвитком комерційної діяльності сайту, пов’язані з
        прийомом і обробкою Замовлень Користувачів відповідно до цієї Політики,
        і інші дії узгоджені з керівництвом ТОВАРИСТВА З ОБМЕЖЕНОЮ
        ВІДПОВІДАЛЬНІСТЮ «ЕР ДЖІ БІ», що не суперечать чинному законодавству
        України. Користувач має право за допомогою телефонного зв’язку, шляхом
        дзвінка Користувача на номер телефону Оператора: +380 (67) 295 93 70,
        +380 (63) 250 78 86 звернутися до Оператора за допомогою у вирішенні
        питань пов’язаних з цією Політикою, в тому числі питання
        відповідальність за рішення або допомогу в рішенні яких несе Оператор, а
        Оператор, при наявності можливості і відповідно запиту Користувача
        Оператору, зобов’язаний сприяти Користувачу.
      </p>
      <h4 className={`${styles.politicSubTitle}`}>2. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
      <p className={`${styles.politicDesc}`}>
        2.1. Використання Користувачем сайту означає згоду з цією Політикою і
        умовами обробки персональних даних Користувача.
      </p>
      <p className={`${styles.politicDesc}`}>
        2.2. У разі незгоди з умовами Політики Користувач повинен припинити
        використання сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        2.3. Ця Політика застосовується тільки до сайту. Сайт не контролює і не
        несе відповідальність за сайти третіх осіб, на які Користувач може
        перейти по посиланнях, доступним на сайті.
      </p>
      <p className={`${styles.politicDesc}`}>
        2.4. Адміністрація не перевіряє достовірність персональних даних, що
        надаються Користувачем сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        2.5. Погоджуючись з умовами цієї Політики Користувач і Адміністрація
        визнають юридичну силу за електронними листами – дoкyмeнтaми,
        спрямованими по електронній пошті (e-mail), і визнають їх рівнозначними
        дoкyмeнтaм на паперових носіях, підписаним власноручним підписом, тому
        що тільки самі Користувач і Адміністрація та уповноважені ними особи
        мають доступ до відповідних адресам електронної пошти і є електронним
        підписом відповідної сторони. Користувач і Адміністрація погоджуються з
        тим, що електронна адреса Адміністрації, що є електронним підписом
        Адміністрації, вважається електронна адреса, зазначена в реквізитах
        Адміністрації в цій Політиці, а електронною адресою Користувача, що є
        електронним підписом Користувача, вважається електронна адреса, вказана
        Користувачем при оформленні Замовлення, або інша адреса електронної
        пошти асоційована Користувачем і адміністрацією з електронною адресою
        Користувача, зазначеної Користувачем при оформленні Замовлення за
        обопільною згодою Користувача і Адміністрації. Доступ до своїх
        електронних поштових скриньок Користувач і Адміністрація здійснюють по
        паролю і зобов’язуються зберігати його конфіденційність.
      </p>
      <h4 className={`${styles.politicSubTitle}`}>3. ПЕРЕДМЕТИ ПОЛІТИКИ</h4>
      <p className={`${styles.politicDesc}`}>
        3.1. Ця Політика встановлює зобов’язання Адміністрації щодо
        нерозголошення та забезпечення захисту конфіденційності персональних
        даних, які Користувач повинен за вимогою надати Адміністрації або при
        оформленні Замовлення.
      </p>
      <p className={`${styles.politicDesc}`}>
        3.2. Персональні дані, дозволені до обробки в рамках цієї Політики,
        надаються Користувачем шляхом заповнення реєстраційної форми на сайті і
        включають в себе наступну інформацію:
      </p>
      <p className={`${styles.politicDesc}`}>
        3.2.1. прізвище та ім’я Користувача;
      </p>
      <p className={`${styles.politicDesc}`}>
        3.2.2. контактний телефон Користувача;
      </p>
      <p className={`${styles.politicDesc}`}>
        3.2.3. адреса електронної пошти (e-mail);
      </p>
      <p className={`${styles.politicDesc}`}>
        3.3. Сайт захищає Дані, які автоматично передаються в процесі перегляду
        рекламних блоків і при відвідуванні сторінок, на яких встановлено
        статистичний скрипт системи (“піксель”): а. IP адреса; б. інформація з
        cookies; в. інформація про браузер (чи іншої програми, яка здійснює
        доступ до показу реклами); г. час доступу; д. адреса сторінки, на якій
        розташований рекламний блок; е. реферер (адреса попередньої сторінки).
      </p>
      <p className={`${styles.politicDesc}`}>
        3.3.1. Відключення cookies може спричинити неможливість доступу до
        частин сайту, що вимагають авторизації.
      </p>
      <p className={`${styles.politicDesc}`}>
        3.3.2. Сайт здійснює збір статистики про IP-адреси своїх відвідувачів.
        Дана інформація використовується з метою виявлення і вирішення технічних
        проблем, для контроля законності проведених фінансових платежів або
        інших цілей Компанії, що не суперечать чинному законодавству України.
      </p>
      <p className={`${styles.politicDesc}`}>
        3.4. Будь-яка інша персональна інформація, не обговорена вище (історія
        покупок, використовувані браузери і операційні системи і т.д.), підлягає
        надійному зберіганню і не розповсюджується, за винятком випадків,
        передбачених в п.п. 5.2. і 5.3. цієї Політики і використовується
        виключно стороною Адміністрації в цілях для поліпшення якості
        обслуговування клієнтів, популяризації товарів і послуг Компанії та
        інших цілей Компанії не суперечать чинному законодавству України у
        неперсоналізованому вигляді.
      </p>
      <h4 className={`${styles.politicSubTitle}`}>
        4. ЦІЛІ ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА
      </h4>
      <p className={`${styles.politicDesc}`}>
        4.1. Персональні дані Користувача Адміністрація може використовувати в
        цілях:
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.1. Ідентифікації Користувача, зареєстрованого на сайті, для
        оформлення Замовлення і (або) укладення договору надання послуг
        дистанційним способом з використанням механізмів сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.2. Надання Користувачеві доступу до персоналізованих ресурсів сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.3. Встановлення з Користувачем зворотного зв’язку, включаючи
        напрямок повідомлень, запитів, що стосуються використання сайту, надання
        послуг, обробка запитів і заявок від Користувача.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.4. Визначення місця знаходження Користувача для забезпечення
        безпеки, запобігання шахрайства, а також для забезпечення зручності
        користування сайтом.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.5. Створення облікового запису для здійснення покупок, якщо
        Користувач дав згоду на створення облікового запису.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.6. Повідомлення Користувача сайту про стан Замовлення.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.7. Обробки і отримання платежів, підтвердження податку або
        податкових пільг, оскарження платежу, визначення права на отримання
        кредитної лінії Користувачем.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.8. Надання Користувачеві ефективної клієнтської і технічної
        підтримки при виникненні проблем, пов’язаних з використанням сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.9. Надання Користувачеві з його згоди, оновлень продукції,
        спеціальних пропозицій, інформації про ціни, розсилки новин та інших
        відомостей від імені сайту або від імені партнерів сайту.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.10. Здійснення рекламної діяльності за згодою Користувача.
      </p>
      <p className={`${styles.politicDesc}`}>
        4.1.11. Надання доступу Користувачеві на сайти або сервіси партнерів
        сайту з метою отримання продуктів, оновлень і послуг.
      </p>
      <h4 className={`${styles.politicSubTitle}`}>
        5. СПОСОБИ І ТЕРМІНИ ОБРОБКИ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ
      </h4>

      <p className={`${styles.politicDesc}`}>
        5.1. Обробка персональних даних Користувача здійснюється без обмеження
        терміну, будь-яким законним способом, в тому числі в інформаційних
        системах персональних даних з використанням засобів автоматизації або
        без використання таких засобів.
      </p>
      <p className={`${styles.politicDesc}`}>
        5.2. Користувач погоджується з тим, що Адміністрація має право
        передавати персональні дані Користувача третім особам, зокрема,
        кур’єрським службам, організаціями поштового зв’язку, операторам
        електрозв’язку, рекламних майданчиків, CRM систем виключно в цілях
        виконання Замовлення, контролю якості обслуговування клієнтів,
        своєчасного надання клієнтам інформації про оновлення продукції,
        спеціальних пропозицій, інформації про ціни, розсилки новин та інших
        відомостей від імені сайту або від імені партнерів сайту, оформленого на
        сайті, включаючи випадки участі представників Компанії в організації
        надання послуг Замовнику.
      </p>
      <p className={`${styles.politicDesc}`}>
        5.3. Персональні дані Користувача можуть бути передані уповноваженим
        органам державної влади тільки на підставах та в порядку, встановленим
        законодавством України.
      </p>
      <p className={`${styles.politicDesc}`}>
        5.4. При втраті або розголошення персональних даних Адміністрація
        інформує Користувача про втрату або розголошення персональних даних.
      </p>
      <p className={`${styles.politicDesc}`}>
        5.5. Адміністрація вживає необхідних організаційних і технічних заходів
        для захисту персональної інформації Користувача від неправомірного або
        випадкового доступу, знищення, перекручення, блокування, копіювання,
        поширення, а також від інших неправомірних дій третіх осіб..
      </p>

      <h4 className={`${styles.politicSubTitle}`}>6. ЗОБОВ’ЯЗАННЯ СТОРІН</h4>
      <p className={`${styles.politicDesc}`}>6.1. Користувач зобов’язаний:</p>
      <p className={`${styles.politicDesc}`}>
        6.1.1. Надати інформацію про персональні дані, необхідну для
        користування сайтом.
      </p>
      <p className={`${styles.politicDesc}`}>
        6.1.2. Оновити, доповнити надану інформацію про персональні дані в разі
        зміни даної інформації.
      </p>
      <p className={`${styles.politicDesc}`}>6.2. Адміністрація зобов’язана:</p>
      <p className={`${styles.politicDesc}`}>
        6.2.1. Використовувати отриману інформацію виключно для цілей,
        зазначених у п. 4 цієї Політики.
      </p>
      <p className={`${styles.politicDesc}`}>
        6.2.2. Забезпечити зберігання конфіденційної інформації в таємниці, не
        розголошувати без попередньої письмової згоди Користувача, а також не
        здійснювати продаж, обмін, опублікування, або розголошення іншими
        можливими способами переданих персональних даних Користувача, за
        винятком п.п. 5.2. і 5.3. цієї Політики.
      </p>
      <p className={`${styles.politicDesc}`}>
        6.2.3. Вживати заходів для захисту конфіденційності персональних даних
        Користувача згідно з порядком, який зазвичай використовується для
        захисту такого роду інформації в існуючому діловому обороті.
      </p>
      <p className={`${styles.politicDesc}`}>
        6.2.4. Здійснити блокування персональних даних, що відносяться до
        відповідного Користувача, з моменту звернення або запиту Користувача або
        його законного представника на період перевірки, в разі виявлення
        недостовірних персональних даних або неправомірних дій.
      </p>

      <h4 className={`${styles.politicSubTitle}`}>
        7. ВІДПОВІДАЛЬНІЧТЬ СТОРІН
      </h4>
      <p className={`${styles.politicDesc}`}>
        7.1. Адміністрація, яка не виконала свої зобов’язання, несе
        відповідальність за збитки, понесені Користувачем у зв’язку з
        неправомірним використанням персональних даних, відповідно до
        законодавства України, за винятком випадків, передбачених п.п. 5.2.,
        5.3. і 7.2. цієї Політики.
      </p>
      <p className={`${styles.politicDesc}`}>
        7.2. У разі втрати або розголошення Конфіденційної інформації
        Адміністрація не несе відповідальність, якщо дана конфіденційна
        інформація:
      </p>
      <p className={`${styles.politicDesc}`}>
        7.2.1. Стала публічним надбанням до її втрати або розголошення.
      </p>
      <p className={`${styles.politicDesc}`}>
        7.2.2. Була отримана законним способом від третьої сторони.
      </p>
      <p className={`${styles.politicDesc}`}>
        7.2.3. Була розголошена за згодою Користувача.
      </p>

      <h4 className={`${styles.politicSubTitle}`}>8. ВИРІШЕННЯ СПОРІВ</h4>
      <p className={`${styles.politicDesc}`}>
        8.1. До звернення в суд з позовом у спорах, що виникають із відносин між
        Користувачем сайту і Адміністрацією сайту, обов’язковим є пред’явлення
        претензії (письмової пропозиції про добровільне врегулювання спору).
      </p>
      <p className={`${styles.politicDesc}`}>
        8.2. Одержувач претензії протягом 30 календарних днів з дня отримання
        претензії, письмово повідомляє заявника претензії про результати
        розгляду претензії.
      </p>
      <p className={`${styles.politicDesc}`}>
        8.3. При не досягненні угоди суперечка буде передана на розгляд до суду
        відповідно до чинного законодавства України.
      </p>
      <p className={`${styles.politicDesc}`}>
        8.4. До цієї Політики і відносин між Користувачем та Адміністрацією
        сайту застосовується чинне законодавство України.
      </p>

      <h4 className={`${styles.politicSubTitle}`}>
        9. РЕКВІЗИТИ (АДМІНІСТРАЦІЇ)
      </h4>
      <p className={`${styles.politicDesc}`}>
        Фізична особа-підприємець Хавро Тетяна Анатоліївна Ідентифікаційний код:
        2964316749 Адреса: 40007, Україна, 40007, Сумська обл., місто Суми,
        ВУЛИЦЯ ХАРКІВСЬКА, будинок 58, корпус А, квартира 15
      </p>
    </section>
  );
};

export default Politic;
