/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styles from '../Home.module.css';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const schema = yup.object().shape({
  username: yup.string().required().min(2, 'Too Short!').max(50, 'Too Long!'),
  phone: yup
    .string()
    .required('')
    .matches(/\+[0-9]{2,3}[0-9]{2,3}[0-9]{2,3}[0-9]{2}[0-9]{2}[0-9]{2}/gi)
    .max(18, ''),
  email: yup.string().required('').email(),
});

const Form = () => {
  const [fullUrl, setFullUrl] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setFullUrl(location.pathname + location.search);
  }, [location.pathname, location.search]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ username, phone, email }) => {
    const data = {
      username,
      phone,
      email,
      fullUrl,
    };
    console.log(data);
    axios
      .post(
        `https://script.google.com/macros/s/AKfycbxOlm75dxMEf9vUbBKtom-7nkdNEePId-q0xj9aQj5ojIXi2OuCTlZf4ccTlRTJxR_Z/exec?p1=${username}&p2=${phone}&p3=${email}&p4=${fullUrl}`
      )
      .then(() => {
        console.log('sheet ok');
      })
      .catch((error) => console.log(error))
      .finally(() => {
        reset();
        navigate('/thanks');
      });
  };

  return (
    <div className={`${styles.form}`}>
      <h3 className={`${styles.formTitle}`}>
        Записаться на <span>бесплатный</span> вебинар!
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label
          htmlFor="username"
          className={`${styles.formLabel}`}
        >
          Ваше имя
          <input
            className={`${styles.formInput} ${
              errors.username ? `${styles.formInputError}` : ''
            }`}
            type="text"
            id="username"
            {...register('username')}
          />
        </label>
        {/* {errors.username && (
          <p className="form__error">{errors.username.message}</p>
        )} */}
        <label
          htmlFor="phone"
          className={`${styles.formLabel}`}
        >
          Номер телефона
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                className={`${styles.formInput} ${
                  errors.username ? `${styles.PhoneInputError}` : ''
                }`}
                defaultCountry="UA"
                international
                value={value}
                onChange={onChange}
                maxLength={18}
              />
            )}
          />
        </label>
        {/* {errors.phone && <p className="form__error">{errors.phone.message}</p>} */}
        <label
          htmlFor="email"
          className={`${styles.formLabel}`}
        >
          Ваш email
          <input
            className={`${styles.formInput} ${
              errors.email ? `${styles.formInputError}` : ''
            }`}
            type="text"
            id="email"
            {...register('email')}
          />
        </label>
        {/* {errors.email && <p className="form__error">{errors.email.message}</p>} */}
        <button
          className={`${styles.btn} ${styles.btnForm}`}
          type="submit"
        >
          Записаться на вебинар
        </button>
        <p className={`${styles.formLink}`}>
          Нажимая на кнопку я даю согласие на&nbsp;
          <Link
            className={`${styles.formLink} ${styles.formLinkUnder}`}
            to="/politic"
          >
            обработку персональных данных
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Form;
