import * as prismic from '@prismicio/client';
import * as prismicH from '@prismicio/helpers';

export const prismicHelper = prismicH;

export const repositoryName = 'khavro-lp';

const client = prismic.createClient(repositoryName, {});

export default client;
