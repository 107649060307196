import * as prismicH from '@prismicio/helpers';
import styles from '../Home.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const Section4 = ({ slice }) => {
  const title = prismicH.asText(slice.primary.title);
  const images = slice.items.map((item) => prismicH.asImageSrc(item.slide));

  return (
    <section className={`${styles.section4}`}>
      <div className={`${styles.sectionBox4}`}>
        <div className={`${styles.title4Box}`}>
          <h2 className={`${styles.title4}`}>{title}</h2>
        </div>
        <div>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={40}
            slidesPerView={'auto'}
            navigation
            pagination={{ clickable: true }}
            style={{ paddingBottom: '55px' }}
          >
            {images.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ width: 'auto' }}
                >
                  <img
                    src={item}
                    alt="Slide image"
                    className={`${styles.slides}`}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Section4;
