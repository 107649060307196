import * as prismicH from '@prismicio/helpers';
import styles from '../Home.module.css';
import { PrismicRichText } from '@prismicio/react';
import { nanoid } from 'nanoid';

const Section2 = ({ slice }) => {
  const title = prismicH.asText(slice.primary.title);

  return (
    <section className={`${styles.section2}`}>
      <div className={`${styles.sectionBox2}`}>
        <h2 className={`${styles.title2}`}>{title}</h2>
        <div className={`${styles.listSec2}`}>
          {slice.items.map((item) => {
            const src = prismicH.asImageSrc(item.icon);
            return (
              <div
                className={`${styles.itemSec2}`}
                key={nanoid}
              >
                <img
                  src={src}
                  alt="icon list"
                />
                <PrismicRichText
                  key={item.item}
                  field={item.item}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Section2;
