import * as prismicH from '@prismicio/helpers';
import styles from '../Home.module.css';
import blur from '../img/blurSec3-1.png';
import blur2 from '../img/blurSec3-2.png';

const Section3 = ({ slice }) => {
  const title = prismicH.asText(slice.primary.title);
  const listItems = slice.items.map((item) => {
    return (item = {
      titleList: prismicH.asText(item.listtitle),
      titleDesc: prismicH.asText(item.itemdesc),
      iconSrc: prismicH.asImageSrc(item.icon),
    });
  });
  const btn = prismicH.asText(slice.primary.btn);
  const desc = prismicH.asText(slice.primary.btndesc);

  const scrollToSection = () => {
    const form = document.getElementById('form');
    form.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className={`${styles.section3}`}>
      <img
        src={blur}
        alt="blur"
        className={`${styles.blurImg1}`}
      />
      <img
        src={blur2}
        alt="blur"
        className={`${styles.blurImg2}`}
      />
      <div className={`${styles.sectionBox3}`}>
        <div className={`${styles.title3Box}`}>
          <h2 className={`${styles.title3}`}>{title}</h2>
        </div>
        <ul className={`${styles.list3}`}>
          {listItems.map((item) => {
            return (
              <li
                key={item.titleList}
                className={`${styles.itemSec3}`}
              >
                <div className={`${styles.itemSec3Box}`}>
                  <p className={`${styles.itemSec3Width}`}>{item.titleList}</p>
                  <img
                    src={item.iconSrc}
                    alt="icon"
                  />
                </div>
                <p className={`${styles.itemSec3desc}`}>{item.titleDesc}</p>
              </li>
            );
          })}
        </ul>
        <div className={`${styles.btnBoxSec3}`}>
          <a
            className={`${styles.btn}`}
            // href="#form"
            onClick={scrollToSection}
          >
            {btn}
          </a>
          <p className={`${styles.descSec3}`}>{desc}</p>
        </div>
      </div>
    </section>
  );
};

export default Section3;
