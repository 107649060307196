import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import styles from '../Home.module.css';

import blur from '../img/sec1-blur.png';
import blur2 from '../img/hero-tab.png';
import blur3 from '../img/hero-mob.png';
import dateIcon from '../img/date.svg';
import timeIcon from '../img/time.svg';

const Hero = ({ slice }) => {
  const heroBannerItems = slice.items.map((item) =>
    prismicH.asText(item?.listitem)
  );

  const btn = prismicH.asText(slice.primary.btn);
  const desc = prismicH.asText(slice.primary.btndesc);
  const img = prismicH.asImageSrc(slice.primary.img);
  const blockTitle = prismicH.asText(slice.primary.bannertitle);
  const blockDesc = prismicH.asText(slice.primary.bannerdesc);
  const time = prismicH.asText(slice.primary.time);
  const date = prismicH.asText(slice.primary.date);

  const scrollToSection = () => {
    const form = document.getElementById('form');
    form.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className={`${styles.hero}`}>
      <img
        src={blur}
        alt="Hero blur"
        className={`${styles.blurImg}`}
      />
      <img
        src={blur2}
        alt="Hero blur"
        className={`${styles.blurImgTab}`}
      />
      <img
        src={blur3}
        alt="Hero blur"
        className={`${styles.blurImgMob}`}
      />
      <div className={`${styles.container}`}>
        <div className={`${styles.containerBtnMob}`}>
          <div className={`${styles.containerBox}`}>
            <div className={`${styles.suptitle}`}>
              <PrismicRichText field={slice.primary.suptitle} />
            </div>
            <div className={`${styles.dateBox}`}>
              <p className={`${styles.date}`}>
                <img
                  src={dateIcon}
                  alt="Icon"
                />
                {date}
              </p>
              <p className={`${styles.time}`}>
                <img
                  src={timeIcon}
                  alt="Icon"
                />
                {time}
              </p>
            </div>
          </div>
          <div className={`${styles.title}`}>
            <PrismicRichText field={slice.primary.title} />
            <PrismicRichText field={slice.primary.desc} />
          </div>
          <a
            className={`${styles.btn}`}
            // href="#form"
            onClick={scrollToSection}
          >
            {btn}
          </a>
          <p className={`${styles.desc}`}>{desc}</p>
        </div>
        <div className={`${styles.img}`}>
          <img
            src={img}
            alt="Hero image"
            width={428}
            height={700}
          />
        </div>
        <div className={`${styles.block}`}>
          <h3 className={`${styles.blockTitle}`}>{blockTitle}</h3>
          <p className={`${styles.blockDesc}`}>{blockDesc}</p>
          {heroBannerItems.map((item) => {
            return (
              <p
                key={item}
                className={`${styles.item}`}
              >
                {item}
              </p>
            );
          })}
          {/* <div>
          {slice.items.map((item) => {
            console.log(item.herobaneritem);
            return (
              <PrismicRichText
                key={item.herobaneritem[0].text}
                field={item.herobaneritem.text}
              />
            );
          })}
        </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
